.anwendernachrichten__AutocompleteContainer {
  min-width: 7rem !important;
  max-width: 7rem !important;
}

.anwendernachrichten__AutocompleteContainer .MuiOutlinedInput-root {
  padding-bottom: 0.1rem !important;
}

.anwendernachrichten__Label {
  width: 10rem !important;
  min-width: 10rem !important;
  max-width: 10rem !important;
}

.anwendernachrichten__TextStyle {
  width: 10rem !important;
  font-size: large !important;
  -webkit-font-smoothing: subpixel-antialiased;
}

.anwendernachrichten__inputTextFieldForNachricht {
  min-width: 70% !important;
}

.datePickerForAnwendernachrichten .MuiOutlinedInput-root {
  min-width: 11rem !important;
  max-width: 11rem !important;
}
