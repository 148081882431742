.tk-partner-card-switch {
  position: absolute;
  left: 0;
}

.tk-partner-card-summary {
  display: flex;
  align-items: center;
}

.tk-partner-card-summary .MuiAccordionSummary-root {
  margin-left: 2.2rem;
  width: 100%;
}
