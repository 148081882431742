@use "global/global.scss" as g;

.title {
  margin-right: 2rem;
}

.toolbar {
  justify-content: space-between;
}

.logout {
  box-shadow: g.$shadow;
}

.menu-open {
  background-color: g.$white;
  box-shadow: g.$shadow;
}

.menu-closed {
  background-color: g.$secondary-main;
  box-shadow: none;
}

.envbackground-toolbar {
  background-color: lightgreen;
}
