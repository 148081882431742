.gridForNotes {
  min-width: 30rem !important;
}

.gridItemForNotes {
  min-width: 28rem !important;
}

.inputTextFieldForNotes,
.inputTextFieldForNotes .MuiOutlinedInput-root,
.inputTextFieldForNotes .MuiFormControl-root {
  min-width: 18rem !important;
  margin-bottom: 0rem !important;
}

.fileNameTextInput {
  min-width: 14rem;
}

.noteDialogTextInput {
  min-width: 26rem;
  font-size: smaller;
}

.labelForNotes {
  width: 5.6rem;
  white-space: nowrap;
}

.fontForNotes {
  font-size: small;
  font-weight: 300;
  color: #222;
}

.createNewNoteButton {
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: fit-content;
}

.datePickerForNotes .MuiOutlinedInput-root {
  min-width: 18rem !important;
  max-width: 18rem !important;
}

.noteInputElement {
  min-width: 334px !important;
}

.noteTextInput {
  min-width: 32rem !important;
  max-width: inherit !important;
  flex-grow: 1;
}

@media (min-width: 1200px) {
  .gridForNotes {
    min-width: 56rem !important;
  }
}

.dialogGrid .MuiGrid-item .epcom__TextValue .MuiInput-root {
  max-width: none !important;
  width: 100%;
}

.epcom__DialogContent .epcom__TextValue {
}

.noteDialogMultilineTextReadonly {
  width: 100% !important;
}

.noteDialogMultilineTextReadonly
  .MuiOutlinedInput-root
  .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.noteDialogMultilineTextReadonlyBox {
  max-width: none !important;
  width: 100%;
  margin-bottom: 0.4rem !important;
  border: 1px solid lightgray;
  border-radius: 0.2rem;
  font-weight: 500 !important;
  font-size: 0.6rem !important;
  background-color: whitesmoke;
  color: #222;
}
