.fieldElementInfo__rowHeader {
  font-weight: 500 !important;
  font-size: 0.6rem !important;
  background-color: whitesmoke;
  padding: 0.1rem 0.2rem 0 0rem;
  text-align: end;
  align-content: center;
  width: 6.4rem !important;
}

.fieldElementInfo__rowContent {
  font-weight: 400 !important;
  font-size: 0.7rem !important;
}

.MuiStack-root .epcom__FieldElementInfoBundle {
  align-items: flex-start;
  margin-bottom: 0.4rem;
}

.epcom__FieldElementInfoBundleContent {
  border: 1px solid lightgray;
  border-radius: 0.2rem;
  padding: 0.2rem;
  align-items: center;
}

.epcom__FieldElementInfoBundleContent .MuiStack-root .Mui-disabled {
  background-color: transparent;
  color: gray;
}
