.inputTextFieldForKaufanfragenStatus {
  min-width: 16rem;
  max-width: 80rem;
}

.dialogHeaderLabelStyle {
  font-size: large !important;
  padding-top: 0.5rem;
}

.dialogLabelStyle {
  width: 7rem !important;
  white-space: nowrap;
}

.dialogLabelStyle2 {
  width: 9rem !important;
  white-space: nowrap;
}

.kaufanfragenDialogInput {
  width: 16rem;
  font-size: smaller;
  margin-right: 1rem;
}

.dialogTextStyle {
  font-size: large !important;
  font-weight: 300;
  -webkit-font-smoothing: subpixel-antialiased;
}
.dialogTextErrorStyle {
  font-size: small !important;
  font-weight: 300;
  color: orangered;
  -webkit-font-smoothing: subpixel-antialiased;
}
