.materialdistribution {
  margin-top: 0.96rem !important;
  max-width: 340px;
}

.materialdistribution_header_label {
  padding-bottom: 0.3rem;
}
.materialdistribution .MuiTypography-root {
  font-size: 0.8rem;
  border-style: inherit;
  border-width: 0.1rem;
}

.materialdistribution .MuiTable-root {
  min-height: 62px;
}
