.deploymentinfo__Popover__popper {
  width: 33.8rem !important;
}

.epcom__SelectWithPopover__disabled__datepicker {
  pointer-events: none;
  opacity: 0.3;
  background-color: transparent !important;
}

.epcom__deploymentInfoContent__umstellungGeplant {
  width: fit-content !important;
}

.epcom__SelectWithPopover__datepicker {
  border: 1px solid khaki;
  border-radius: 1rem;
}
