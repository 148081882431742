.jobmonitoring__MultiInputStyle {
  min-width: 18rem;
  width: fit-content;
  max-width: 33rem;
  font-size: smaller;
}

.jobmonitoring__LabelStyle {
  width: 10rem !important;
  white-space: wrap;
  font-size: medium !important;
  font-weight: 300;
  -webkit-font-smoothing: subpixel-antialiased;
}
