.relativeGrid {
  width: 100%;
  max-height: 500;
}
.ag-row .ag-cell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.ag-cell-center-justified-content {
  justify-content: center !important;
}
.ag-theme-alpine .ag-header-cell,
.ag-theme-alpine .ag-header-group-cell {
  padding-left: 6px;
  padding-right: 2px;
}

.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  padding-left: 4px;
  padding-right: 4px;
}

.gridActionsRow {
  flex-direction: row;
  align-items: flex-end;
  padding: 0.25rem;
  height: 0;
}

.withGridActionsRow {
  margin-top: 48px;
  padding-bottom: 1rem;
}

.ag-theme-alpine {
  --ag-foreground-color: rgb(60, 60, 60);
  --ag-header-foreground-color: rgb(28, 101, 177);
  --ag-header-background-color: #f5f7f7;
  --ag-selected-row-background-color: rgb(28, 101, 177, 0.1) !important;
}

.actionCell {
  cursor: pointer;
}

.dataGrid___cacheAgeProgress {
  position: relative;
  right: 2.5rem;
  top: 1rem;
  z-index: 2000;
  pointer-events: none;
}

.dataGrid__cacheDataActionContainer {
  position: relative;
  left: 2rem;
  top: 0.36rem;
}
