@import "../global.scss";

.uiElement {
  display: inline-flex;
  float: left;
}

.epcom__CheckBox {
  margin-bottom: 0.8rem;
  margin-top: 0.4rem;
}

.uiCheckbox {
  width: 1rem;
  height: 1rem;
  margin-left: 0.5rem;
  cursor: pointer;
}

.uiElement__Container,
.epcom__TextValue,
.epcom__SelectBox,
.epcom__DatePicker,
.epcom__CheckBox,
.epcom_FileSelect,
.epcom__SelectWithPopover,
.epcom__FieldElementInfoBundle {
  flex-direction: row;
  align-items: center;
}

.uiElement__Text {
  font-weight: 300;
  color: #222;
}

.uiElement__Label {
  width: 8rem;
  font-size: small;
}

.uiElement__TextElement {
  min-width: 18rem;
  max-width: 23rem;
  font-size: smaller;
}

.uiElement__InputWithBox {
  margin-bottom: 0.25rem;
}

.uiElement__Switch {
  margin-top: 0.25rem;
}

.MuiAutocomplete-listbox,
.MuiAutocomplete-paper {
  width: 26rem;
}
.MuiInputAdornment-positionEnd {
  margin-left: -0.25rem;
}

.MuiCheckbox-root {
  padding: 0;
}

.statusIcon__Text {
  font-size: 14px;
  font-weight: bold;
  padding-left: 0.2rem;
}

.artikelSucheResponseCard {
  border: 1px solid #222;
  border-radius: 0.5rem;
  margin-top: 2rem;
  padding-bottom: 1rem;
}

.box-with-centered-content {
  display: flex;
  justify-content: center;
}

.checkboxActionProgress {
  position: absolute;
  top: 4px;
  z-index: 10;
}
.MuiBackdrop-root,
.MuiDialog-backdrop {
  background-color: rgba(0, 0, 0, 0.3);
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}

.epcom__TextValue .MuiTypography-root,
.epcom__SelectBox .MuiTypography-root,
.epcom__SelectWithPopover .MuiTypography-root,
.epcom__DatePicker .MuiTypography-root,
.epcom__CheckBox .MuiTypography-root,
.epcom_FileSelect .MuiTypography-root,
.epcom__FieldElementInfoBundle .MuiTypography-root {
  font-weight: 300;
  color: #222;
  width: 8rem;
  font-size: small;
}

.epcom__TextValue .MuiInput-root,
.epcom__TextValue .MuiTextField-root,
.epcom__SelectBox .MuiAutocomplete-root,
.epcom__FieldElementInfoBundleContent {
  min-width: 18rem;
  max-width: 23rem;
  font-size: smaller;
}

.epcom__TextValue .MuiFormControl-root,
.epcom__SelectBox .MuiFormControl-root,
.epcom__DatePicker .MuiFormControl-root,
.epcom_FileSelect .MuiFormControl-root {
  margin-bottom: 0.25rem;
}

.epcom__DatePicker .MuiOutlinedInput-root,
.epcom_FileSelect .MuiOutlinedInput-root {
  min-width: 18rem;
  max-width: 23rem;
  font-size: smaller;
}

.MuiDialog-root pre {
  font: inherit !important;
}

.epcom__Popover__popper {
  padding: 1rem;
  background-color: white;
  border: 1px solid #2196f3;
  z-index: 1300;
  border-radius: 1rem;
  overflow: hidden;
  margin-top: 0.63rem !important;
  margin-bottom: 0.63rem !important;
}

.epcom__Popover__popper .MuiBox-root {
  width: min-content !important;
}

.epcom__Popover__popper * .MuiTypography-h2 {
  font-size: 0.9rem !important;
  font-weight: 400 !important;
}
