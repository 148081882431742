.wgrzuordnung__TextStyle {
  font-size: large !important;
  width: 7rem !important;
  -webkit-font-smoothing: subpixel-antialiased;
}

.wgrzuordnung__rowStack {
  flex-direction: row;
  align-items: center;
}

.wgr__dialogLabelStyle {
  white-space: nowrap;
  padding-bottom: 0.1rem;
  width: 9rem !important;
}

.wgr__dialogSmallLabelStyle {
  width: 4rem !important;
}

.wgr__longTextStyle {
  max-width: 34rem !important;
  width: 34rem !important;
}

.wgrFilter__dialogInputStyle {
  font-size: small;
  font-weight: 300;
  -webkit-font-smoothing: subpixel-antialiased;
}

.MuiGrid-root .withGridActionsRow .wgrzuordnung__rowStack {
  margin-left: -0.5rem;
}
