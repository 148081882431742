.abrufButton {
  margin-right: 0.75rem;
}

.networkOpResult {
  white-space: pre-line;
  font-family: "Courier New", Courier, monospace;
  font-size: small;
}

.networkOpResultBox {
  max-height: 400px;
  overflow-y: scroll;
}
