.dialog__CreateUnplausibleRechnungenEvent .MuiDialog-container .MuiDialog-paper,
.dialog__UnplausibleRechnungenFilterDialog
  .MuiDialog-container
  .MuiDialog-paper {
  border-radius: 1rem;
  border: 1px solid #2196f3;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  padding: 0 0 0 0;
  max-width: 640px;
}

.dialog__CreateUnplausibleRechnungenEvent
  .MuiDialog-container
  .MuiDialog-paper
  .MuiDialogContent-root
  .MuiDialogContent-root {
  //   border: 1px solid red;
  padding: 0;
}

.dialog__CreateUnplausibleRechnungenEvent .actionsButtonContainer {
  padding: 0 1.5rem 1.5rem 0;
}

.dialog__SuchenStack {
  padding: 1rem 1rem 0 1rem;
  align-items: center;
}

.dialog__SuchenError {
  padding: 0 0 0 7rem;
}

.dialog__SuchenButton {
  flex: auto;
  height: 2.3rem;
}

.dialog__ReferenzLabel {
  font-size: medium !important;
  width: 9rem !important;
}

.dialog_FilterLogicLabel {
  font-size: large;
  font-weight: 400;
}

.rechnungen-status-multiselect .MuiStack-root .MuiBox-root {
  width: 100%;
}

.dialog__SuchenProgress {
  position: absolute;
  top: 2.2rem;
  right: 9.4rem;
  z-index: 2000;
  pointer-events: none;
}
.unplausibleRechnungen__dataGrid__statusOptions {
  border-color: rgba($color: #2196f3, $alpha: 0.4);
  font-size: medium;
  color: rgb(60, 60, 60);
}

.ag-header-cell-comp-wrapper .box-with-centered-content {
  padding-left: 1.68rem;
}

.inputTextFieldForFilterSelect {
  min-width: 12.2rem !important;
  max-width: 12.2rem !important;
}

.inputTextFieldForFilterValue {
  min-width: 12.2rem !important;
  max-width: 12.2rem !important;
}

.MuiTypography-root .MuiSvgIcon-root {
  vertical-align: text-top;
  margin: 0 0.4rem 0 0.4rem;
}

.datePickerForFilterDialog .MuiOutlinedInput-root {
  min-width: 12.2rem !important;
  max-width: 12.2rem !important;
  height: 2.6rem !important;
}
