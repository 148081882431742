.labelForLogfile,
.labelForLogfile .MuiTypography-root {
  width: 4rem !important;
  white-space: nowrap;
}

.logfile__Header {
  flex-direction: row;
  align-items: center;
  height: 35px;
  min-width: 14rem;
}

.logfile__AutocompleteContainer,
.logfile__AutocompleteContainer .MuiFormControl-root,
.logfile__AutocompleteContainer .MuiOutlinedInput-root {
  min-width: 14rem;
  max-width: 14rem;
}

.logfile__inputTextFieldForDate,
.logfile__inputTextFieldForDate .MuiFormControl-root,
.logfile__inputTextFieldForDate .MuiOutlinedInput-root {
  min-width: 14rem;
}

.logfile__buttonFilter {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
