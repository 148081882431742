.status-tagcount-slider {
  padding-top: 6px;
}

.status-filter-button {
  margin-top: -6px;
}

.status-mm-first-ok-time {
  margin-left: 0.5rem;
  font-weight: 500;
  font-size: 0.84rem;
  //   color: seagreen;
  color: darkolivegreen;
}
