.actionsButtonContainer {
  justify-content: flex-end;
  display: flex;
}

.actionButtonContainer {
  justify-content: center;
}

.dialogButton {
  border-radius: 6px;
  min-width: 10rem;
}

.dialogTitle {
  font-size: larger;
  font-weight: bold;
}

.modalMessageDialog__nonInteractiveOverlay {
  width: 100%;
  height: 100%;
  background-color: rgba(240, 248, 255, 0.9);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalMessageDialog__nonInteractiveContainer {
  flex-direction: row;
  align-items: center;
  padding: 2rem;
}

.MuiDialogContentText-root pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}
