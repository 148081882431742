.positionDetails__TextStyle {
  font-size: small;
  font-weight: 300;
  -webkit-font-smoothing: subpixel-antialiased;
}

.positionDetails__InputStyle {
  font-size: large;
  font-weight: 300;
  width: 24rem !important;
  -webkit-font-smoothing: subpixel-antialiased;
}

.plzSuche__gridCellButtonWidth {
  min-width: 8rem !important;
}
