.maintenanceui__StatusStyle {
  width: 29rem !important;
  white-space: nowrap;
  font-size: large !important;
  -webkit-font-smoothing: subpixel-antialiased;
  border-bottom: 1px solid #e0e0e0;
}
.maintenanceui__StatusStyleActive {
  width: 29rem !important;
  white-space: nowrap;
  font-size: larger !important;
  font-weight: 900;
  color: orangered;
  -webkit-font-smoothing: subpixel-antialiased;
  border-image: linear-gradient(to right, orangered, hotpink) 50;
  border-width: 0 0 3px 0;
  border-style: solid;
}

.maintenanceui__MessageInputStyle {
  width: 29rem !important;
}

.maintenanceui___applyButton {
  width: 11rem;
}

.maintenanceui___endButton {
  margin-left: 2rem;
}

.maintenanceui___applyProgress {
  position: relative;
  right: 9.6rem;
  top: 0.7rem;
  z-index: 2000;
  pointer-events: none;
  width: 32px !important;
  height: 32px !important;
}

.snackbar-close-button {
  color: whitesmoke;
  background-color: transparent;
}

.notistack-SnackbarContainer:has(
    div
      > div.notistack-CollapseWrapper
      > div.notistack-Snackbar
      > div.snackbar-maintenance
  ) {
  margin-right: 4rem;
}

.snackbar-maintenance {
  position: absolute;
  top: -1rem;
}
