@import "../../../../../../../global/global.scss";

.compactElement6rem {
  min-width: 6rem !important;
  width: 6rem !important;
}

.compactDatePickerElement {
  min-width: 9rem !important;
  width: 9rem !important;
}

.MuiOutlinedInput-root:has(div):has(input):has(#dpgKategorieSeit),
.MuiOutlinedInput-root:has(div):has(input):has(#itSicherheitskonzeptSeit),
.MuiOutlinedInput-root:has(div):has(input):has(#aleVersionSeit) {
  max-width: 10rem !important;
  min-width: 10rem !important;
}

.compactElementXrem {
  min-width: 1.5rem !important;
  width: 1.5rem !important;
}

.epcom__SelectWithPopover {
  margin-top: -0.25rem !important;
}

.epcom__SelectWithPopover .MuiOutlinedInput-root {
  min-width: 13rem;
  max-width: 13rem;
  font-size: smaller;
}

.epcomAutomatDetailsHeader {
  flex-direction: row;
  padding: 0.5rem 0.5rem 0.5rem 9rem;
  background-color: $secondary-main;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  border-bottom: 1px solid lightgray;
  margin-bottom: 0.5rem !important;
}

.epcomAutomatDetailsHeaderLabel {
  font-size: 1rem;
  font-weight: 500;
  min-width: 8rem;
}

.epcom__AutomatDeploymentInfoElement_DatePicker .MuiOutlinedInput-root {
  max-width: 10rem !important;
  min-width: 10rem !important;
}
