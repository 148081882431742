.anlageneueruser__LabelStyle {
  width: 7.5rem !important;
  white-space: nowrap;
  font-size: large !important;
  -webkit-font-smoothing: subpixel-antialiased;
}

.anlageneueruser__InputStyle {
  width: 16rem !important;
  margin-right: 1rem;
}
