.lieferDateLabelStyle {
  width: 10rem !important;
  white-space: nowrap;
}

.lieferDateInput,
.lieferDateInput .MuiOutlinedInput-root .MuiOutlinedInput-input {
  width: 10rem !important;
  font-size: smaller;
  margin-right: 1rem !important;
}

.lieferDateInput .MuiOutlinedInput-root {
  min-width: 11rem !important;
}
